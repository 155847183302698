import { categorySV } from "./category.service";
// import jQuery from "jquery";
// window.jQuery = jQuery;
// window.$ = jQuery;
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap";
export default {
  data: function () {
    return {
      nameCate: "",
      typeSecond: "",
      listCate: []
    };
  },
  methods: {
    createNewCate: function () {
      if (
        this.nameCate &&
        this.nameCate.length > 0 &&
        this.typeSecond &&
        this.typeSecond.length > 0
      ) {
        categorySV.createCate(
          JSON.stringify({
            name: this.nameCate,
            type: "marketplace",
            mode: "public",
            type_second: this.typeSecond
          }),
          function (data) {
            console.log(data);
          }
        );
      }
    },
    updateCategory: function (vt) {
      var thisVue = this;
      categorySV.updateCategory(JSON.stringify(this.listCate[vt]), function (data) {
        thisVue.getAllCategory();
      });
    },
    deleteCategory: function (vt) {
      var thisVue = this;
      categorySV.deleteCategory(JSON.stringify({id: thisVue.listCate[vt].id}), function(data) {
        thisVue.getAllCategory();
      })
    },
    getAllCategory: function () {
      var thisVue = this;
      categorySV.getAllCategory($.param({
        type: 'marketplace'
      }), function (data) {
        if (data && data.code === 200) {
          thisVue.listCate = data.data;
        }
      });
    }
  },
  mounted: function () {
    var thisVue = this;
    thisVue.getAllCategory();
  }
};
